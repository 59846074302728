@media(prefers-reduced-motion: no-preference)
{
  @keyframes FadeIn {
    0% {
      opacity: 0;
      position: relative;
      top: -36px;
    }
    50% {
      position: relative;
      top: 0px;
    }
    100% {
      opacity: 100%;
    }
  }
  
  @keyframes FadeInLeft {
    0% {
      opacity: 0;
      position: relative;
      left: -64px;
    }
    50% {
      position: relative;
      left: 0px;
    }
    100% {
      opacity: 100%;
    }
  }
}

.sectionHeader {
  font-size: 32pt;
  padding-top: 15pt;
  padding-bottom: 5pt;
  text-align: center;
}

.transparent
{
  opacity: 0%;
}

.fadeInTop
{
  animation-name: FadeIn;
  animation-duration: 2s;
  animation-fill-mode: both;
}