@media(prefers-reduced-motion: no-preference)
{  
  @keyframes FadeInLeft {
    0% {
      opacity: 0;
      position: relative;
      left: -64px;
    }
    50% {
      position: relative;
      left: 0px;
    }
    100% {
      opacity: 100%;
    }
  }
}

.integrationsContainer {
  margin-left: 16px;
}

.integrationImg {
  border-color: white;
  border-radius: 50%;
  border-style: solid;
}

.fadeInLeft
{
  animation-name: FadeInLeft;
  animation-duration: 3s;
  animation-delay: 1250ms;
  animation-fill-mode: both;
}