html {
    overflow-x: hidden;
}

body {
    background-color: #121212;
    overflow-x: hidden;
}

.clear{width: 100%; clear: both; height: 0px; line-height:0px;}

.panel {
    background-color: rgba(255, 255, 255, .11);
}

.horizontal {
    margin: 0;
    padding: 0;
}

.horizontal li {
    display: inline;
    padding-right: 10pt;
}

.inlineBlock
{
    display: inline-block;
}

.vertAligner {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.alignRight {
    display: flex;
    justify-content: flex-end;
}

.inlineBlock {
    display: inline-block;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center; 
}

.left {
    justify-content: left;
    align-items: left; 
}

.NoFlex {
    display: block;
}

.maxWidth {
    width: 100%;
}

.sidePaddingXV {
    padding-left: 15px;
    padding-right: 15px;
}

.vertPaddingX {
    padding-top: 10px;
    padding-bottom: 10px;
}

.vertPaddingXV {
    padding-top: 15px;
    padding-bottom: 15px;
}

h1 {
    color: #dddddd;
    font-family: 'Roboto Slab', serif;
    margin-bottom: 0;
}

h2 {
    color: #dddddd;
    font-family: 'Roboto Slab', serif;
    margin-top: 0;
    margin-bottom: 0;
}

h3 {
    color: #dddddd;
    font-family: 'Roboto Slab', serif;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20pt;
    font-weight: bold;
    text-align: center;
}

h4 {
    color: #dddddd;
    font-family: 'Roboto Slab', serif;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14pt;
    font-weight: bold;
    text-align: center;
}

p {
    color: #dddddd;
    font-family: 'Roboto Slab', serif;
    margin-top: 0;
    margin-bottom: 0;
}

b {
    color: #ffffff
}

.text-center {
    text-align: center;
}

.infoParagraph {
    margin-top: -16px;
}

a {
    color: inherit;
    text-decoration: inherit;
}

b {
    overflow-wrap: normal;
}

.circleImg {
    border-color: white;
    border-radius: 100%;
    border-style: solid;
}