@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

@media(prefers-reduced-motion: no-preference)
{
  @keyframes FadeIn {
    0% {
      opacity: 0;
      position: relative;
      top: -36px;
    }
    50% {
      position: relative;
      top: 0px;
    }
    100% {
      opacity: 100%;
    }
  }
  
  @keyframes FadeInLeft {
    0% {
      opacity: 0;
      position: relative;
      left: -64px;
    }
    50% {
      position: relative;
      left: 0px;
    }
    100% {
      opacity: 100%;
    }
  }
  
  @keyframes GoopDown {
    0% {
      top: -100vh;
      opacity: 100%;
    }
    40% {
      top: 0vh;
      opacity: 100%;
    }
    60% {
      top: 0vh;
      opacity: 100%;
    }
    90% {
      opacity: 0%;
    }
    99% {
      opacity: 0%;
      top: 80vh;
    }
    100% {
      opacity: 0%;
      top: -100vh;
    }
  }
  
  @keyframes LogoAppear {
    0% {
      background-image: url(/src/assets/img/empty_sized.png);
    }
    49% {
      background-image: url(/src/assets/img/empty_sized.png);
    }
    50% {
      background-image: url(/src/assets/img/logo.png);
    }
    100% {
      background-image: url(/src/assets/img/logo.png);
    }
  }
}

@media(prefers-reduced-motion: reduce)
{
  
  @keyframes GoopDown {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 0%;
    }
  }
}

.sizedToScreen {
  height: 80vh;
  width: 80vw;
  margin: 40pt;
  margin-top: 5vh;
  margin-bottom: 5vh;
  
}

.title {
  font-size: min(min(72pt, 6.9vh), 7.9vw);
  padding-top: 10pt;
  padding-left: 10pt;
  padding-right: 10pt;
  animation-name: FadeIn;
  animation-duration: 2s;
  animation-delay: 250ms;
  animation-fill-mode: both;
}

.subtitle {
  font-size: min(min(36pt, 3.45vh), 3.45vw);
  padding-top: 0pt;
  padding-left: 10pt;
  padding-right: 10pt;
  animation-name: FadeInLeft;
  animation-duration: 3s;
  animation-delay: 1250ms;
  animation-fill-mode: both;
}

.introSection {
  margin: 0;
  margin-top: -5vh;
  width: 100vw;
  height: 110vh;
  display: flex;
  justify-content: center;
  align-items: center; 
  background-color: black;
}

.logoContainer {
  display: inline-block;
  width: 50vw;
  height: 50vw;
  max-width: 600px;
  max-height: 600px;
  margin-right: 10vw;
}

.logo {
  background-image: url(/src/assets/img/logo.png);
  width: 50vw;
  height: 50vw;
  max-width: 600px;
  max-height: 600px;
  padding-bottom: 100%;
  z-index: 1;
  animation-name: LogoAppear;
  animation-duration: 3s;
  animation-delay: 0ms;
  animation-fill-mode: both;
  background-size: contain;
  overflow: visible;
  background-repeat: no-repeat;
}

.goop {
  position: relative;
  background-image: url(/src/assets/img/Goop.png);
  width: 50vw;
  height: calc(50vw * 1.9);
  max-width: 600px;
  max-height: calc(600px * 1.9);
  z-index: 2;
  animation-name: GoopDown;
  animation-duration: 3s;
  animation-delay: 0ms;
  animation-fill-mode: both;
  background-size: contain;
  background-repeat: no-repeat;
}

.IntroContainer {
  margin-left: 40px;
}

.spacerDiv {
  max-width: 300px;
  flex-grow: 1;
}

.middle {
  display: flex;
  justify-content: center;
}

.infoSection {
  background-color: #121212;
  margin: 0px;
  padding-top: 0px;
  width: 100vw;
}

.langHeader {
  padding-top: 1vh;
  font-size: max(1.2vw, 12pt);
}

.ProjectHeader {
  font-size: max(1.5vw, 16pt);
}

.ProjectText {
  padding-top: 1vh;
  font-size: max(0.8vw, 8pt);
}

.ProjectText {
  padding-top: 1vh;
  font-size: max(1vw, 10pt);
}

.langImg {
  width: max(2vw, 30px);
  height: max(2vw, 30px);
  padding-right: max(0.75vw, 15px);
}

.langSection {
  padding-top: 1vh;
}