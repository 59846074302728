
.footer {
  width: 100%;
  height: 300px;
  background-color: #080808;
}

.footer p{
  text-align: center;
}

.transparent
{
  opacity: 0%;
}

.fadeInTop
{
  animation-name: FadeIn;
  animation-duration: 2s;
  animation-fill-mode: both;
}